.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.dropdown-user-actions {
  background: #3c536c;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #fff;
  padding: 5px 0 6px 0;
  width: 100%;
}
.dropdown-user-actions .dropdown-item {
  display: block;
  background: transparent;
  color: rgb(255, 255, 255);
  padding: 6px 20px 7px;
  transition: color 0.2s linear;
}
.dropdown-user-actions .dropdown-item:hover, .dropdown-user-actions .dropdown-item:focus {
  color: #fff;
  background: transparent;
}
.dropdown-user-actions svg {
  display: inline-block;
  margin-right: 14px;
}
.dropdown-user-actions .icon-wrap {
  display: inline-block;
  width: 26px;
}
.dropdown-user-actions .icon-wrap svg {
  margin-right: 0;
}
.dropdown-user-actions .has-new-messages {
  position: relative;
}
.dropdown-user-actions .has-new-messages::before {
  content: "";
  background: #e92422;
  border-radius: 50%;
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  right: 10px;
  top: 1px;
}
.top-nav-has-new-messages {
  position: relative;
}
.top-nav-has-new-messages::before {
  content: "";
  background: #e92422;
  border-radius: 50%;
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  right: -2px;
  top: 0;
}
@media (min-width: 992px) {
.user-menu-info {
    display: flex;
    align-items: center;
}
.user-menu-info .icon {
    margin-left: 12px;
    margin-top: -4px;
}
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}