.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.login-form-container {
  background: #f4f4f4;
  padding: 1rem;
  position: relative;
}
.login-form-container .login-form-close {
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  transition: color 0.2s ease-in-out;
}
.login-form-container .container {
  max-width: 350px;
}
.login-form-container .login-label {
  color: #2e4159;
  margin: 0.5rem auto 1rem auto;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
@media (min-width: 1200px) {
.login-form-container .login-label {
    display: none;
}
}
.login-form-container .form-group {
  display: block;
  margin-bottom: 4px;
  width: 100%;
}
.login-form-container .form-control {
  margin-bottom: 0;
  width: 100%;
}
.login-form-container .input-group {
  width: 100%;
  flex-wrap: nowrap;
}
.login-form-container .input-group .form-control {
  width: auto;
}
.login-form-container .input-group .input-group-append {
  background-color: #fff;
}
.login-form-container .action-container {
  width: 100%;
}
.login-form-container .login-button {
  display: block;
  width: 100%;
  max-width: 245px;
  margin: 19px auto 21px auto;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  border-radius: 23px;
}
@media (min-width: 992px) {
.login-form-container .login-button {
    width: 180px;
    max-width: 205px;
    margin: 0;
    order: 1;
}
}
.login-form-container .lost-password, .login-form-container .signup-link {
  display: block;
  margin: 1rem auto 26px;
  text-decoration: underline;
  text-align: center;
}
@media (min-width: 992px) {
.login-form-container {
    background-color: #2e4159;
    min-width: 384px;
    padding: 22px 11px 23px 4px;
}
.login-form-container .login-form-close {
    color: #fff;
    right: 0;
    top: 0;
}
.login-form-container .login-form-close:hover, .login-form-container .login-form-close:focus, .login-form-container .login-form-close:active {
    color: #e92422;
}
.login-form-container .container {
    max-width: 100%;
}
.login-form-container .signup-link {
    display: none;
}
.login-form-container .login-label {
    display: none;
}
.login-form-container .form-group {
    display: block;
    margin-bottom: 17px;
    padding-right: 4px;
}
.login-form-container .action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.login-form-container .lost-password {
    color: #fff;
    line-height: 2.3;
    margin: 0;
    text-decoration: underline;
    order: 0;
    white-space: nowrap;
}
.login-form-container .lost-password:hover {
    text-decoration: none;
}
}