.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.topbar-menu-container {
  display: none;
  max-width: 70%;
}
.topbar-menu-container .dropdown-menu-right {
  margin-top: 0;
}
.topbar-menu-container > .nav-item > .nav-link {
  font-size: 0.75rem;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  padding-bottom: 0;
}
@media (min-width: 992px) {
.topbar-menu-container {
    display: flex;
    justify-content: right;
    margin-right: -0.5rem;
}
.topbar-menu-container > .nav-item {
    margin-left: 21px;
}
.topbar-menu-container > .nav-item > .nav-link {
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 9px;
    line-height: 21px;
}
.topbar-menu-container > .nav-item > .nav-link:hover, .topbar-menu-container > .nav-item > .nav-link:focus {
    background: #3c536c;
}
.topbar-menu-container > .nav-item.show > .nav-link {
    background: #3c536c;
}
}
.b-nav-dropdown {
  text-align: center;
  min-width: 10rem;
}