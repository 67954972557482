.cc-window.cc-type-categories .cc-btn.cc-save, .cc-window.cc-type-categories .cc-btn.cc-btn-settings {
  padding: 8px 20px;
}
.user-menu {
  background-color: #f4f4f4;
  padding: 65px 0 5px 0;
}
.user-menu .user-info {
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
}
@media (min-width: 380px) {
.user-menu .user-info {
    margin-left: -21px;
}
}
.user-menu .user-info h5 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
}
.user-menu .user-info .user-icon {
  flex: 0 0 75px;
  padding-top: 1.5rem;
  text-align: center;
  color: white;
  border-radius: 1rem;
  height: 75px;
  width: 75px;
  background-color: #52759f;
}
.user-menu .user-info .user-data {
  margin-left: 1rem;
  margin-top: 9px;
}
.user-menu .user-links {
  display: block;
  font-weight: 500;
  letter-spacing: 0.9px;
  margin: 1.5rem 0 0 0;
  width: 100%;
  text-transform: uppercase;
}
.user-menu svg {
  display: inline-block;
  margin-right: 14px;
}
.user-menu .icon-wrap {
  display: inline-block;
  width: 26px;
}
.user-menu .icon-wrap svg {
  margin-right: 0;
}
.user-menu .has-new-messages {
  position: relative;
}
.user-menu .has-new-messages::before {
  content: "";
  background: #e92422;
  border-radius: 50%;
  display: block;
  width: 6px;
  height: 6px;
  position: absolute;
  right: 10px;
  top: 1px;
}
@media (min-width: 992px) {
.user-menu {
    display: none;
    visibility: hidden;
}
}